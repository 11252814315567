import React from "react";
import { Button, ButtonColour, ButtonSize } from "./Button";

interface Props {
    title: string,
    subtitle?: string,
    icon?: JSX.Element,
    ctaLabel?: string,
    ctaLink?: string,
    ctaAriaLabel?: string,
    className?: string,
}

export const HeaderInfo: React.FC<Props> = ({ title, subtitle, icon, ctaLabel, ctaLink, ctaAriaLabel, className = "", children }) => {
    return (
        <div className={`header-info-container ${className}`}>
            {icon &&
                icon
            }
            <div className="header-info">
                <h1 className="title">{title}</h1>
                {subtitle && <h2 className="subtitle">{subtitle}</h2>}
                {ctaLink && ctaLabel &&
                    <Button
                        buttonConfig={
                            {
                                id: "blueGradient",
                                ctaLabel,
                                ctaLink,
                                size: ButtonSize.LARGE,
                                colour: ButtonColour.WHITE,
                                ariaLabel: ctaAriaLabel,
                            }
                        }
                    />
                }
            </div>
            {children}
        </div>
    )
}